import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ReactQuill from 'react-quill';
import { Form, Input, InputNumber, Checkbox, Upload, Button, Select, Divider } from 'antd'
import AnimatedImage from '../../components/AnimatedImage'
import { UploadOutlined } from '@ant-design/icons';
import { BlogCard } from './ViewBlog'
import 'firebase/storage';
import 'react-quill/dist/quill.snow.css';

export const AddBlogForm = ({ handleSubmit, posts }) => {
  const [form] = Form.useForm()
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [body, setBody] = useState("");
  const [cover, setCover] = useState(null)
  const [previewImage, setPreviewImage] = useState();

  const initialValues = {
    title,
    description,
    body,
    cover
  }

  // useEffect(() => {
  //   const { title, description, body } = form.getFieldsValue()
  //   console.log('addblogform useeffect', title, description, body)
  //   title && setTitle(title)
  //   description && setDescription(description)
  //   body && setBody(body)
  // }, [form, title, description, body])

  return (
    <>
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <StyledUpload name="cover" label="Cover Image">
        <Upload>
          <Button icon={<UploadOutlined />}>Click to Upload Cover Image</Button>
        </Upload>
      </StyledUpload>
      <Form.Item name="title" label="Title">
        <Input onChange={e => setTitle(e.target.value)} />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input onChange={e => setDescription(e.target.value)} />
      </Form.Item>
      <Form.Item name="body" label="Body">
        <ReactQuill onChange={e => setBody(e)} theme="snow" value={body}/>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add post
        </Button>
      </Form.Item>
    </Form>
      <Divider />
      {
        (cover || title || description || body) ?
        <BlogCard 
          cover={previewImage}
          title={title}
          description={description}
          body={body}
          avatar={<AnimatedImage avatar />}
        /> :
        <></>
      }
    </>
  )
}

const StyledUpload = styled(Form.Item)`
  max-width: 500px;
  margin: 20px auto;
  .ant-form-item-label {
    min-width: 100px;
    text-align: left;
  }
`;