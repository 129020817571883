import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu, Modal, Row, Col, Button, Image, Divider } from 'antd'
import { Header as Head } from '../../components'
import styled from 'styled-components'
import fallback from '../../assets/fallback_image.png'

export const ScriptsPage = ({ scripts }) => {
  const { Header } = Layout;
  const [sortedScripts, setSortedScripts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeScript, setActiveScript] = useState();

  const showModal = (script) => {
    setActiveScript(script);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!!scripts.length) {
      setSortedScripts(
        scripts?.sort((a, b) => {
          return a.order - b.order;
        })
      )
    }
  }, [scripts])


  return (
    <Layout>
      <Header className="header" id="home" >
        <Menu theme="dark" mode="horizontal" >
          <Menu.Item key="1"><a href="#home">Home</a></Menu.Item>
          <Menu.Item key="2"><a href="#about">About</a></Menu.Item>
          <Menu.Item key="3"><a href="#scripts">Scripts</a></Menu.Item>
          <Menu.Item key="4"><Link to="/blog">Blog</Link></Menu.Item>
          <Menu.Item key="5"><a href="https://teespring.com/gat-finger-tm-www-gatfinger" target="_blank">Shop</a></Menu.Item>
          <Menu.Item key="6">Contact</Menu.Item>
        </Menu>
      </Header>
      <StyledHeader>
        <Head />
      </StyledHeader>
      <ScriptsSection id="scripts" justify="center">
        {
          sortedScripts?.map((script, i) => {
            if (script?.isActive) {
              return (
                <Script
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={4}
                  xxl={2}
                >
                  <div>
                    <Image src={script?.cover} fallback={fallback} />
                    <h4>{script?.title}</h4>
                    <div>{script?.short}</div>
                  </div>
                  <StyledButton onClick={() => showModal(script)} type="default">View Script</StyledButton>
                </Script>  
              )
            }
          })
        }
        <Modal
          title={activeScript?.title}
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <Row gutter={[8]}>
            <Col span={8}>
              <Image src={activeScript?.cover} alt={`${activeScript?.title} cover`} style={{ width: "100%"}} fallback={fallback} />
            </Col>
            <Col span={16}>
              <Row>
                <Col span={4}>Title:</Col>
                <Col span={20}>{activeScript?.title}</Col>
              </Row>
              <Row>
                <Col span={4}>Genre:</Col>
                <Col span={20}>{activeScript?.genre.map(x => <span style={{ padding: "0 4px" }}>{x}</span>)}</Col>
              </Row>
              <Row>
                <Col span={4}>Short:</Col>
                <Col span={20}>{activeScript?.short}</Col>
              </Row>
              <Row>
                <Col span={4}>State:</Col>
                <Col span={20}>{activeScript?.forSale ? "For Sale" : "DRAFT"}</Col>
              </Row>
              <Row>
                <Col span={4}>Pages:</Col>
                <Col span={20}>{activeScript?.pages}</Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row justify="space-around">
            {activeScript?.script && <Button href={activeScript?.script} download target="_blank">Script</Button>}
            {activeScript?.logline && <Button href={activeScript?.logline} download target="_blank">Logline & Synopsis</Button>}
            {activeScript?.mp3 && <Button href={activeScript?.mp3} download target="_blank">Podcast/MP3</Button>}
          </Row>
        </Modal>
      </ScriptsSection>
    </Layout>
  )
}

const ScriptsSection = styled(Row)`
  margin: 20px;
`

const Script = styled(Col)`
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* flex: 1; */
  /* flex-wrap: wrap; */
  margin: 6px;
  padding: 4px 6px;
  border-radius: 4px;
  transition: background .2s ease-in-out;
  &:hover {
    background: rgb(230, 232, 235);
  }
  & img {
    width: 100%;
  }
  & h4 {
    text-align: center;
  }
`

const StyledButton = styled(Button)`
  margin: 8px 0;
`

const StyledHeader = styled.div`
  background: #fff;
` 
