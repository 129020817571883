import React from 'react'
import { useHistory } from 'react-router-dom'
import { FileAddOutlined, EditOutlined, MehOutlined, FileTextOutlined, UserAddOutlined, OrderedListOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Layout, Card, Tabs, PageHeader, Button, Row } from 'antd'
import { LoginForm } from './LoginForm'
import { AddScriptPage } from '../features/edit'
import { EditScriptsPage } from '../features/edit'
import { EditAboutPage } from '../features/about'
import { EditBlogPage, AddBlogPage } from '../features/blog'
import { ReorderScripts } from '../features/edit/components/ReorderScripts'

export const AuthPage = ({ firebase, genres, scripts, body, posts, authUser }) => {
  const { TabPane } = Tabs;
  const history = useHistory();
  return (
    <Layout>
      {
        !authUser ?
        <LoginForm firebase={firebase} /> :
        <>
          <PageHeader
            title="EDIT MODE"
            subTitle="You are currently in edit mode. Making changes here will immediately update the live site."
            extra={
              <Button danger onClick={() => firebase.doSignOut().then(() => history.push('/'))}>Logout</Button>
            }
          />
          <Card>
            <Tabs type="card">
              <TabPane tab={<><FileAddOutlined />&nbsp;Add Script</>} key="1">
                <AddScriptPage firebase={firebase} genres={genres} scripts={scripts} />
              </TabPane>
              <TabPane tab={<><EditOutlined />&nbsp;Edit Scripts</>} key="2">
                <Row align="center">
                  <h3><OrderedListOutlined />&nbsp;Script Order</h3>
                </Row>
                <StyledRow align="center">
                  <ReorderScripts scripts={scripts} firebase={firebase} />
                </StyledRow>
                <StyledRow align="center">
                  <EditScriptsPage scripts={scripts} genres={genres} firebase={firebase} />
                </StyledRow>
              </TabPane>
              <TabPane tab={<><MehOutlined />&nbsp;Edit About</>} key="3">
                <EditAboutPage body={body} firebase={firebase} />
              </TabPane>
              <TabPane tab={<><UserAddOutlined />&nbsp;Add Blog Entry</>} key="4">
                <AddBlogPage posts={posts} firebase={firebase} />
              </TabPane>
              <TabPane tab={<><FileTextOutlined />&nbsp;Edit Blog</>} key="5">
                <EditBlogPage posts={posts} firebase={firebase} />
              </TabPane>
            </Tabs>
          </Card>
        </>
      }
    </Layout>
  )
}

const StyledRow = styled(Row)`
  margin: 20px;
`