import React from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'

export const Bio = ({text}) => {
  return (
    <div>
      <Title dangerouslySetInnerHTML={{ __html: text?.title }} />
      <div dangerouslySetInnerHTML={{ __html: text?.text }} />
      <Divider />
    </div>
  )
}

const Title = styled.h2`
  font-weight: bold;
`