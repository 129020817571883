import React from 'react'
import styled from 'styled-components'
import footerImage from '../../assets/contact.svg'

export const Footer = () => {
  return (
    <StyledFooter>
      <img src={footerImage} alt="" />
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  background: #f0f2f5;
  padding: 20px;
  & img {
    width: 60%;
  }
`