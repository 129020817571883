import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import AnimatedImage from '../../components/AnimatedImage'
import moment from 'moment'
import { Tabs, Layout, Menu, Tooltip, Card, Avatar } from 'antd'
import { Footer as GatFooter } from '../../components';

export const ViewBlog = ({posts}) => {
  
  const [blogPosts, setBlogPosts] = useState();
  const { TabPane } = Tabs;
  const { Header, Content, Footer } = Layout;
  useEffect(() => {
    setBlogPosts(posts.sort((a, b) => (a.date > b.date) ? 1 : -1));
  }, [posts])

  return (
    <Layout>
      <Header className="header" id="home" >
        <Menu theme="dark" mode="horizontal" >
          <Menu.Item key="1"><a href="/">Home</a></Menu.Item>
          <Menu.Item key="2"><a href="/#about">About</a></Menu.Item>
          <Menu.Item key="3"><a href="/#scripts">Scripts</a></Menu.Item>
          <Menu.Item key="4" active><Link to="/blog">Blog</Link></Menu.Item>
          <Menu.Item key="5"><a href="https://teespring.com/gat-finger-tm-www-gatfinger" target="_blank">Shop</a></Menu.Item>
          <Menu.Item key="6">Contact</Menu.Item>
        </Menu>
      </Header>
       <Content style={{ padding: '0 50px' }}>
       <StyledContent>
       {
         posts?.length ?

        <Tabs defaultActiveKey="1" tabPosition="right">
          {
            posts.map((post, index) => {
              return (
                <TabPane tab={
                  <Tooltip placement="left" title={moment(post.date).format('MMMM Do YYYY, h:mm:ss a')}>
                    <BlogCard 
                      cover={post.cover} 
                      title={post.title} 
                      description={
                        <Tooltip title={moment(post.date).format('MMMM Do YYYY, h:mm:ss a')}>
                          <span>{moment(post.date).fromNow()}</span>
                        </Tooltip>
                      }
                      isTab 
                      />
                  </Tooltip>
                  } 
                  key={index}>
                 <BlogCard 
                  cover={post.cover}
                  avatar={<AnimatedImage avatar />}
                  title={post.title} 
                  description={post.description} 
                  body={post.body}
                  date={
                    <Tooltip title={moment(post.date).format('MMMM Do YYYY, h:mm:ss a')}>
                      <span>{moment(post.date).fromNow()}</span>
                    </Tooltip>
                  }
                />
                </TabPane>
              )
            })
          }
        </Tabs> :
        <h2>
          Blog coming soon...
        </h2>
       }
       </StyledContent>
      </Content>
      <Footer>
        <GatFooter />
      </Footer>
    </Layout>
  )
}

export const BlogCard = ({ cover, avatar, title, description, body, date, isTab }) => {
  const { Meta } = Card;
  const tabStyle = {
    width: 200,
  }
  return (
    <Card
      style={isTab && tabStyle}
      cover={
        cover ?
        <img
          alt="example"
          src={cover}
        /> :
        <></>
      }
    >
      <Meta
        avatar={isTab ? <></> : <Avatar src={avatar} />}
        title={title}
        description={description}
      />
      <p>
        {date}
      </p>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </Card>
  )
}

const StyledContent = styled.div`
  margin-top: 24px;
  min-height: 280px;
  padding: 24px;
  background: #fff;
  transition: any 2s ease-in-out;
  /* .site-layout-content {
  } */
`