import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card } from 'antd'
import { storage } from "../../firebase/firebase"
import { AddScriptForm } from './components'

export const AddScriptPage = ({ firebase, genres, scripts }) => {
  const history = useHistory();
  const handleSubmit = (values) => {
    const { genre, isActive, order, pages, title, forSale, short, cover, logline, script, mp3 } = values;
    const textValues = { genre, isActive, order, pages, title, forSale, short };
    const files = [ cover, logline, script, mp3 ].filter(x => !!x);

    firebase.createScript(textValues)
      .then(ref => {
        const docId = ref.id;
        files?.map(file => {
          console.log('start of upload')
          if(file === '') {
            console.error(`not an image, the image file is a ${typeof(file)}`)
          }
          const newFile = file.originFileObj;
          
          const uploadTask = storage().ref(`/${docId}/${newFile.name}`).put(newFile);
          uploadTask.on('state_changed',
            function (snapshot) {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            }, 
            function (err) {
              console.log(err)
            }, function() {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                    console.log("File available at", downloadURL);
                    firebase.updateScript({ docId, values: { [file.title]: downloadURL } })
              });
              storage().ref(docId).child(newFile.name).getDownloadURL()
                .then(fireBaseUrl => {
                  firebase.updateScript({ docId, values: { cover: fireBaseUrl } })
                })
            }
          )

        })
      })
      .then(() => history.push('/'))
      .catch(err => console.error(err))
  }
  return (
    <Card>
      <h3>Add Script Page</h3>
      <AddScriptForm handleSubmit={handleSubmit} genres={genres} scripts={scripts} />
    </Card>
  )
}