import React, { useEffect, useState } from 'react'
import { Tabs, Row } from 'antd'
import { MehOutlined } from '@ant-design/icons'
import { EditWelcome, EditGroup, EditAwards, EditMisc } from './components'

export const EditAboutPage = ({ body, firebase }) => {
  const { TabPane } = Tabs;
  const [welcome, setWelcome] = useState();
  const [awards, setAwards] = useState();
  const [bio, setBio] = useState();
  const [misc, setMisc] = useState();

  console.log('fsfhsd', body)

  useEffect(() => {
    if (!!body?.length) {
      setWelcome(body.find(item => item.docId === "welcome"));
      setAwards(body.find(item => item.docId === "awards"));
      setBio(body.find(item => item.docId === "bio"));
      setMisc(body.find(item => item.docId === "misc"));
    }
  }, [body])

  const handleSubmit = (values) => {
    firebase.updateBody({ docId: values.docId, values });
  }
  
  return (
    <>
      <Row align="center">
        <h3>
          <MehOutlined />&nbsp;Edit About
        </h3>
      </Row>
      <Tabs>
        <TabPane tab="Welcome" key="1">
          <EditWelcome text={welcome}  handleSubmit={handleSubmit} />
        </TabPane>
        <TabPane tab="Bio" key="2">
          <EditGroup text={bio} handleSubmit={handleSubmit} />
        </TabPane>
        <TabPane tab="Awards" key="3">
          <EditGroup text={awards} handleSubmit={handleSubmit} />
        </TabPane>
        <TabPane tab="Misc" key="4">
          <EditGroup text={misc} handleSubmit={handleSubmit} />
        </TabPane>
      </Tabs>
    </>
  )
}