import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Card, PageHeader, Button, } from 'antd'

export const LoginForm = ({firebase}) => {
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
   firebase.onAuthStateChanged(user => {
      if (user) history.push('/admin/add')
   })
  }, []);

  const handleFinish = (values) => {
    const { email, password } = values;
    firebase.doSignInWithEmailAndPassword(email, password).then(res => {
      console.log('logged in?', res)
      // redirect to /admin
      history.push('/admin')
    }).catch(err => console.log(err))
  }

  const initialValues = {
    email: "",
    password: ""
  }
  
  return (
    <Card>
      <PageHeader title="Sign in" />
      <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
        <Form.Item label="Email" name="email">
          <Input type="email" placeholder="fakeemail@email.com" />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input type="password" placeholder="password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Sign in</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}