import React from 'react'
import { useHistory } from 'react-router-dom'
import { UserAddOutlined } from '@ant-design/icons'
import { Card, Row } from 'antd'
import { storage } from "../../firebase/firebase"
import { AddBlogForm } from '.'

export const AddBlogPage = ({ posts, firebase }) => {
  const history = useHistory();
  const handleSubmit = (values) => {
    const { title, description, body, cover } = values;
    const textValues = { title, description, body };
    const file = cover;

    firebase.createPost(textValues)
      .then(ref => {
        const docId = ref.id;
        if (file === '') {
          console.error(`not an image, the image file is a ${typeof(file)}`)
        }
        const newFile = file.file.originFileObj;

        const uploadTask = storage().ref(`/posts/${docId}/${newFile.name}`).put(newFile);
        uploadTask.on('state_changed',
          function (snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          function (err) {
            console.log(err)
          }, function() {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                console.log('File available at', downloadURL);
                firebase.updatePost({ docId, values: { cover: downloadURL }})
              });
              storage().ref(`posts/${docId}`).child(newFile.name).getDownloadURL()
                .then(fireBaseUrl => {
                  firebase.updatePost({ docId, values: { cover: fireBaseUrl }})
                })
          }
        )
      })
      .then(() => history.push('/admin'))
      .catch(err => console.error(err))
  }

  return (
    <Card>
      <Row align="center">
        <h3><UserAddOutlined />&nbsp;Add Blog Page</h3>
      </Row>
      <AddBlogForm handleSubmit={handleSubmit} />
    </Card>
  )
}