import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { Welcome, Bio, Awards, Misc } from './components'

export const AboutPage = ({body}) => {
  const [welcome, setWelcome] = useState();
  const [awards, setAwards] = useState();
  const [bio, setBio] = useState();
  const [misc, setMisc] = useState();

  useEffect(() => {
    if (!!body?.length) {
      setWelcome(body.find(item => item.docId === "welcome"));
      setAwards(body.find(item => item.docId === "awards"));
      setBio(body.find(item => item.docId === "bio"));
      setMisc(body.find(item => item.docId === "misc"));
    }
  }, [body])
  return (
    <Row id="about" style={{ marginTop: "30px" }}>
      <Col span={12} offset={6}>
        <Welcome text={welcome}  />
        <Bio text={bio}  />
        <Awards text={awards}  />
        <Misc text={misc}  />
      </Col>
    </Row>
  )
}