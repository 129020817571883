import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Input, InputNumber, Checkbox, Upload, Button, Select, Popconfirm } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import 'firebase/storage';



export const EditScriptsForm = ({script, genres, firebase, handleSubmit, handleDelete}) => {
  const [form] = Form.useForm();
  const { title, short, genre, pages, forSale: scriptForSale, isActive: scriptIsActive, order } = script;

  const { TextArea } = Input;

  const [scriptFile, setScriptFile] = useState("");
  const [logline, setLogline] = useState("");
  const [cover, setCover] = useState("");
  const [mp3, setMp3] = useState("");
  
  const [isActive, setIsActive] = useState(scriptIsActive);
  const [forSale, setForSale] = useState(scriptForSale);

  const initialValues = {
    docId: script?.docId,
    title,
    short,
    genre,
    pages,
    forSale,
    isActive,
    order,
  }

  useEffect(() => {
    form.setFieldsValue({ docId: script?.docId })
  }, [script]);
  useEffect(() => {
    scriptFile && form.setFieldsValue({ script: { ...scriptFile, title: "script" } })
  }, [, scriptFile])
  useEffect(() => {
    logline && form.setFieldsValue({ logline: { ...logline, title: "logline" } })
  }, [, logline])
  useEffect(() => {
    mp3 && form.setFieldsValue({ mp3: { ...mp3, title: "mp3" } })
  }, [, mp3])
  useEffect(() => {
    cover && form.setFieldsValue({ cover: { ...cover, title: "cover" } })
  }, [, cover])
  useEffect(() => {
    form.setFieldsValue({ isActive })
  }, [, isActive])
  useEffect(() => {
    form.setFieldsValue({ forSale })
  }, [, forSale])

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Form.Item hidden name="docId" />
      <Form.Item name="title" label="Title">
        <Input />
      </Form.Item>
      <Form.Item name="short" label="Short">
        <TextArea />
      </Form.Item>
      <Form.Item name="genre" label="Genres">
        <Select
          mode="multiple"
          placeholder="Please select genres"
        >
          {genres?.map(genre => {
              return (<Select.Option key={`edit-script-genre-${genre}`} value={genre} />)
          })}
        </Select>
      </Form.Item>
      <Form.Item name="pages" label="Number of pages">
        <InputNumber />
      </Form.Item>
      <Form.Item name="forSale" label="Is for sale">
        <Checkbox checked={forSale} onChange={(e) => setForSale(e.target.checked)} />
      </Form.Item>
      <Form.Item name="isActive" label="Publicly visible?">
        <Checkbox defaultChecked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
      </Form.Item>


      <StyledUpload name="cover" label="Cover Image">
        <Upload 
          onChange={(e) => setCover(e.file)}
        >
          <Button icon={<UploadOutlined />}>Click to Upload Cover Image</Button>
        </Upload>
      </StyledUpload>
      <StyledUpload name="logline" label="Logline">
        <Upload 
          onChange={(e) => setLogline(e.file)}
        >
          <Button icon={<UploadOutlined />}>Click to Upload Logline</Button>
        </Upload>
      </StyledUpload>
      <StyledUpload name="script" label="Script">
        <Upload 
          onChange={(e) => setScriptFile(e.file)}
        >
          <Button icon={<UploadOutlined />}>Click to Upload Script</Button>
        </Upload>
      </StyledUpload>
      <StyledUpload name="mp3" label="mp3">
        <Upload 
          onChange={(e) => setMp3(e.file)}
        >
          <Button icon={<UploadOutlined />}>Click to Upload MP3</Button>
        </Upload>
      </StyledUpload>

      <ButtonContainer>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
          <Popconfirm
            title="Are you sure to delete this script? This can't be undone."
            onConfirm={() => handleDelete(script?.docId)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">
              Delete
            </Button>
          </Popconfirm>
      </ButtonContainer>
    </Form>
  )
}


const StyledUpload = styled(Form.Item)`
  max-width: 500px;
  margin: 20px auto;
  .ant-form-item-label {
    min-width: 100px;
    text-align: left;
  }
`;

const ButtonContainer = styled(Form.Item)`
  max-width: 500px;
  margin: auto;
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
  }
  .ant-btn-primary {
    margin: 20px 0;
  }
`;