import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill';
import { Form, Button, Input } from 'antd'
import 'react-quill/dist/quill.snow.css';

export const EditGroup = ({text, handleSubmit}) => {
  const [form] = Form.useForm();
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const initialValues = {
    docId: id,
    title,
    text: body
  }

  useEffect(() => {
    if (text) {
      setTitle(text.title);
      setBody(text.text);
      setId(text.docId);
    }
  }, [text]);

  useEffect(() => {
    if (!!body?.length) {
      form.setFieldsValue({ text: body })
    }
    if (!!title.length) {
      form.setFieldsValue({ title: title })
    }
    if (!!id.length) {
      form.setFieldsValue({ docId: id })
    }
  }, [body, id]);

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleSubmit}>
      <Form.Item hidden name="docId" />
      <Form.Item name="title" label="Title" >
        <Input />
      </Form.Item>
      <Form.Item name="text">
        <ReactQuill theme="snow" value={body} onChange={setBody}/>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Update
        </Button>
      </Form.Item>
    </Form>
  )
}