import React from 'react'
import { useHistory } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import { Tabs, Row } from 'antd'
import { EditScriptsForm } from './components'
import { storage } from "../../firebase/firebase"

export const EditScriptsPage = ({scripts, genres, firebase}) => {
  const history = useHistory();
  scripts = scripts?.sort((a, b) => a.order - b.order);
  const { TabPane } = Tabs;

  const handleSubmit = (values) => {
    Object.keys(values).forEach(key => (values[key] === undefined || values[key] === null) && delete values[key])
    
    const slash = "%2F";
    const doc = scripts?.find(x => x?.docId === values?.docId)
    const docId = doc?.docId;
    const coverName = doc?.cover?.slice(doc?.cover?.indexOf(slash) + slash?.length, doc?.cover?.indexOf("?"))
    const scriptName = doc?.script?.slice(doc?.script?.indexOf(slash) + slash?.length, doc?.script?.indexOf("?"))
    const loglineName = doc?.logline?.slice(doc?.logline?.indexOf(slash) + slash?.length, doc?.logline?.indexOf("?"))
    const mp3Name = doc?.mp3?.slice(doc?.mp3?.indexOf(slash) + slash?.length, doc?.mp3?.indexOf("?"))

    const { genre, isActive, pages, title, forSale, short, cover, logline, script, mp3 } = values;
    const textValues = { genre, isActive, pages, title, forSale, short };
    const files = [ cover, logline, script, mp3 ].filter(x => !!x);
    
    firebase.updateScript({ docId: values.docId, values: textValues })
      .then(() => {
        files?.map(file => {
          console.log('start of upload')
          if(file === '') {
            console.error(`not an image, the image file is a ${typeof(file)}`)
          }
          const newFile = file.originFileObj;
          
          const uploadTask = storage().ref(`/${docId}/${newFile.name}`).put(newFile);
          uploadTask.on('state_changed',
            function (snapshot) {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            }, 
            function (err) {
              console.log(err)
            }, function() {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                    console.log("File available at", downloadURL);
                    firebase.updateScript({ docId, values: { [file.title]: downloadURL } })
              });
              storage().ref(docId).child(newFile.name).getDownloadURL()
                .then(fireBaseUrl => {
                  firebase.updateScript({ docId, values: { [file.title]: fireBaseUrl } })
                })
            }
          )

        })
      })
      .then(() => {
        if (values?.cover) {
          console.log('removing cover')
          firebase?.deleteFile({docId, fileName: coverName})
        }
        if (values?.script) {
          console.log('removing script')
          firebase?.deleteFile({docId, fileName: scriptName})
        }
        if (values?.logline) {
          console.log('removing logline')
          firebase?.deleteFile({docId, fileName: loglineName})
        }
        if (values?.mp3) {
          console.log('removing mp3')
          firebase?.deleteFile({docId, fileName: mp3Name})
        }
      })
      .then(() => history.push('/'))
      .catch(err => console.error(err))
  }

  const handleDelete = (id) => {
    firebase?.deleteScript(id)
      .then(() => {
        const ref = storage().ref(id)
        ref.listAll().then((file) => {
          file.items.forEach(item => {
            item.delete()
          })
        })
      })
      .then(() => history.push('/'))
      .catch(err => console.error(err))
  }
  
  return (
    <>
      <Row align="center">
        <h3><EditOutlined />&nbsp;Edit Scripts</h3>
      </Row>
      <Tabs defaultActiveKey="1">
        {scripts?.map((script, i) => 
          <TabPane tab={script.title} key={`edit-script-${i}`}>
            <EditScriptsForm key={i} script={script} genres={genres} firebase={firebase} handleSubmit={handleSubmit} handleDelete={handleDelete} />
          </TabPane>
        )}
      </Tabs>
    </>
  )
}