import 'antd/dist/antd.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import moment from 'moment'
import { compose } from 'recompose';
import { AuthPage } from './auth';
import { Footer } from './components';
import { ScriptsPage } from './features';
import { AboutPage } from './features/about';
import { ViewBlog } from './features/blog/ViewBlog'
import { withFirebase } from './firebase';
import { withAuthentication } from './session';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scripts: [],
      genres: [],
      body: [],
      posts: [],
      authUser: null
    }
  }
  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser ? 
      this.setState({ authUser }) :
      this.setState({ authUser: null });
    });
    const { getScripts, genre, getBody, getPosts } = this.props.firebase;
    getScripts().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const { genre, isActive, order, pages, title, forSale, short, cover, logline, script, mp3 } = doc.data();
        this.setState({
          scripts: [
            ...this.state.scripts,
            { docId: doc.id, genre, isActive, order, pages, title, forSale, short, cover, logline, script, mp3 }
          ]
        })
      })
    });
    
    genre().then((querySnapshot) => {
      const genres = [];
      querySnapshot.forEach((doc) => {
        genres.push(doc.data());
      })
      this.setState({
        genres
      })
    });
    getBody().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.setState({
          body: [
            ...this.state.body,
            { docId: doc.id, ...doc.data() }
          ]
        })
      })
    });
    getPosts().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const { date, title, body, cover, description } = doc.data();
        this.setState({
          posts: [
            ...this.state.posts,
            { docId: doc.id, title, body, cover, description, date }
          ]
        })
      })
    });
  }
  render() {
    return (
      <Router>
          <Route path="/" exact>
            <ScriptsPage scripts={this.state.scripts} />
            <AboutPage body={this.state.body} />
            <Footer />
          </Route>
          <Route path="/blog" exact>
            <ViewBlog posts={this.state.posts} />
          </Route>
          <Route
            path="/admin"
            exact
          >
            <AuthPage 
              authUser={this.state.authUser}
              firebase={this.props.firebase} 
              genres={this.state.genres} 
              scripts={this.state.scripts} 
              body={this.state.body} 
              posts={this.state.posts}
            />
          </Route>
      </Router>
    )
  }
} 


export default compose(withAuthentication, withFirebase)(App)