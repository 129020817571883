import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Input, InputNumber, Checkbox, Upload, Button, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import 'firebase/storage';

export const AddScriptForm = ({ handleSubmit, genres, scripts }) => {
  const [form] = Form.useForm();
  const [script, setScript] = useState("");
  const [logline, setLogline] = useState("");
  const [cover, setCover] = useState("");
  const [mp3, setMp3] = useState("");
  const [order, setOrder] = useState(0);
  const [forSale, setForSale] = useState(false);
  const [isActive, setIsActive] = useState(false);
  
  const { TextArea } = Input;

  const initialValues = {
    title: "",
    short: "",
    genre: [],
    pages: 0,
    forSale,
    isActive,
    order: order,
    script: null,
    logline: null,
    mp3: null,
    cover: null
  }

  useEffect(() => {
    isActive && form.setFieldsValue({ isActive });
    forSale && form.setFieldsValue({ forSale });
  }, [form, isActive, forSale])

  useEffect(() => {
    script && form.setFieldsValue({ script: { ...script, title: "script" } })
  }, [, script])
  useEffect(() => {
    logline && form.setFieldsValue({ logline: { ...logline, title: "logline" } })
  }, [, logline])
  useEffect(() => {
    mp3 && form.setFieldsValue({ mp3: { ...mp3, title: "mp3" } })
  }, [, mp3])
  useEffect(() => {
    cover && form.setFieldsValue({ cover: { ...cover, title: "cover" } })
  }, [, cover])
  useEffect(() => {
    if (!!scripts?.length) {
      setOrder(scripts?.length);
      form.setFieldsValue({ order })
    }
  }, [scripts]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <OffsetColors 
        name="title" 
        label="Title" 
        autoComplete="off"
        rules={[{ required: true, message: 'Please add a title' }]}
      >
        <Input />
      </OffsetColors>
      <OffsetColors name="genre"  label="Genres">
        <Select
          mode="multiple"
          placeholder="Please select genres"
          optionLabelProp="label"
        >
          {genres?.map(genre => {
            return (<Select.Option key={`add-script-genre-${genre.id}`} label={genre.title} value={genre.title} />)
          })}
        </Select>
      </OffsetColors>
      <OffsetColors 
        name="short" 
        label="Short"
        rules={[{ required: true, message: 'Please add a title' }]}
      >
        <TextArea />
      </OffsetColors>
      <OffsetColors name="pages" label="Number of pages">
        <InputNumber />
      </OffsetColors>
      <Form.Item name="order" hidden />
      <OffsetColors name="forSale" label="Is for sale">
        <Checkbox onChange={e => setForSale(e.target.checked)} />
      </OffsetColors>
      <OffsetColors name="isActive" label="Publicly visible?">
        <Checkbox onChange={e => setIsActive(e.target.checked)} />
      </OffsetColors>

      <StyledUpload name="cover" label="Cover Image">
        <Upload 
          onChange={(e) => setCover(e.file)}
        >
          <Button icon={<UploadOutlined />}>Click to Upload Cover Image</Button>
        </Upload>
      </StyledUpload>
      <StyledUpload name="logline" label="Logline">
        <Upload 
          onChange={(e) => setLogline(e.file)}
        >
          <Button icon={<UploadOutlined />}>Click to Upload Logline</Button>
        </Upload>
      </StyledUpload>
      <StyledUpload name="script" label="Script">
        <Upload 
          onChange={(e) => setScript(e.file)}
        >
          <Button icon={<UploadOutlined />}>Click to Upload Script</Button>
        </Upload>
      </StyledUpload>
      <StyledUpload name="mp3" label="mp3">
        <Upload 
          onChange={(e) => setMp3(e.file)}
        >
          <Button icon={<UploadOutlined />}>Click to Upload MP3</Button>
        </Upload>
      </StyledUpload>


      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add script
        </Button>
        <Button type="text" onClick={() => {
          setScript("");
          setLogline("");
          setCover("");
          setMp3("");
          form.setFieldsValue({ ...initialValues });
        }}>
          Clear Form
        </Button>
      </Form.Item>
    </Form>
  )
}

const StyledUpload = styled(Form.Item)`
  max-width: 500px;
  margin: 20px auto;
  .ant-form-item-label {
    min-width: 100px;
    text-align: left;
  }
`;

const OffsetColors = styled(Form.Item)`
  .ant-form-item-label {
    min-width: 120px;
    text-align: left;
  }
  &:nth-child(odd) {
    background: rgb(240,240,240);
    padding: 20px 10px;
    margin-bottom: 0px;
  }
  &:nth-child(even) {
    background: #fff;
    padding: 20px 10px;
    margin-bottom: 0px;
  }
`