import React from 'react'
import { PageHeader } from 'antd'
import AnimatedImage from '../AnimatedImage'
import hero from '../../assets/hero.svg'

export const Header = () => {
  return (
    <PageHeader
      title={<img style={{ width: "100%" }} src={hero} alt="Cumberlain Rattlesnake's Gat Finger" />}
      extra={<AnimatedImage />}
    /> 
  )
}