import React, { Component } from 'react'
import styled from 'styled-components'
import me from '../assets/mypicture.png'
import toon from '../assets/animation/gatfinger/gf_1.png'
import toon2 from '../assets/animation/gatfinger/gf_2.png'
import classNames from 'classnames'

class AnimatedImage extends Component {
  state = {
    animate: false
  }
  interval = setInterval(() => {
    setTimeout(() => {
      this.setState({ animate: true })
    }, Math.floor(Math.random() * 2000) + 1)
    return this.setState({ animate: false })
  }, 10000)

  render() {
    const rotateTime = '10s'
    const rotateStyle = {
      "-webkit-animation": `rotating ${rotateTime} linear infinite`,
      "-moz-animation": `rotating ${rotateTime} linear infinite`,
      "-ms-animation": `rotating ${rotateTime} linear infinite`,
      "-o-animation": `rotating ${rotateTime} linear infinite`,
      "animation": `rotating ${rotateTime} linear infinite`,
    }
    const avatarStyles = {
      width: "32px",
      height: "32px",
      display: "flex"
    }
    return (
      <AnimatedStyles 
        className={classNames(this.interval, "rotate")} 
        style={this.props.avatar ? { ...avatarStyles, ...rotateStyle } : rotateStyle}
        >
        <MeStyled src={me} alt="" />
        <ToonStyled
          className={`${this.state.animate ? 'animate' : ''} flash`}
          src={toon}
          alt=""
        />
        <Toon2Styled
          className={`${this.state.animate ? 'animate' : ''} fade`}
          src={toon2}
          alt=""
        />
      </AnimatedStyles>
    )
  }
}

const MeStyled = styled.img`
  width: 100%;
  position: absolute;
  z-index: 1;
`
const ToonStyled = styled.img`
  width: 100%;
  position: absolute;
  z-index: 1;
  visibility: hidden;
`
const Toon2Styled = styled.img`
  width: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;
`
const AnimatedStyles = styled.div`
  position: relative;
  width: 155px;
  height: 155px;
  overflow: hidden;
  border: 2px solid black;
  border-radius: 50%;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  animation: spin 10s linear infinite;
  .animate.fade {
    opacity: 0;
    animation: 0.5s alternate 10 fade;
  }
  .animate.flash {
    visibility: hidden;
    animation: 0.2s 2s alternate 10 flash;
  }
  @keyframes flash {
    0% {
      filter: invert(0);
      visibility: visible;
    }
    25% {
      filter: invert(1);
    }
    50% {
      filter: invert(0);
    }
    100% {
      filter: invert(1);
      visibility: hidden;
    }
  }
  @keyframes fade {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

`

export default AnimatedImage
