import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_APP_ID,
  measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
}
const copyDoc = process.env.REACT_APP_COPY_DOC;


class Firebase {
  constructor() {
    firebase.initializeApp(config)
    this.auth = firebase.auth()
    this.db = firebase.firestore()
    this.storage = firebase.storage()
    this.storageRef = this.storage.ref()
    this.imagesRef = this.storageRef.child('images')
    console.log('IMAGES', this.storageRef)

    //onAuthStateChanged
  }

  // Auth API

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  doPasswordReset = () => (email) => this.auth.sendPasswordResetEmail(email)

  onAuthStateChanged = () => (user) => this.auth.onAuthStateChanged(user)

  // Scripts API

  getScripts = () => this.db.collection('scripts').get();
  updateScript = ({docId, values}) => this.db.collection('scripts').doc(docId).update(values);
  createScript = (values) => this.db.collection('scripts').add(values);
  deleteScript = (docId) => this.db.collection('scripts').doc(docId).delete();

  genre = () => this.db.collection('genre').get();
  
  // Body
  getBody = () => this.db.collection('body').get();
  updateBody = ({docId, values}) => {
    console.log('firebase', docId, values)
    this.db.collection('body').doc(docId).update(values)
  };

  // Blog Posts
  getPosts = () => this.db.collection('posts').get();
  createPost = (values) => this.db.collection('posts').add(values);
  updatePost = ({docId, values}) => {
    console.log('firebase', docId, values)
    this.db.collection('posts').doc(docId).update(values);
  };
  deletePost = (docId) => {
    this.db.collection('posts').doc(docId).delete();
  }

  deleteFile = ({docId, fileName}) => this.storage.ref(`/${docId}/${fileName}`).delete();



}

export const storage = () => firebase.storage();

export default Firebase
