import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill';
import { Form, Button, Input, Popconfirm } from 'antd'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import 'react-quill/dist/quill.snow.css';

export const EditBlog = ({ post, firebase }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [description, setDescription] = useState('');
  const [cover, setCover] = useState();

  const initialValues = {
    docId: post?.docId,
    title,
    body,
    description,
    cover
  }

  useEffect(() => {
    if (post) {
      const {id, title, description, body, cover } = post;
      setId(id);
      setTitle(title);
      setBody(body);
      setDescription(description);
      setCover(cover);
    }
  }, [post])

  useEffect(() => {
    id && form.setFieldsValue({ docId: id });
    title && form.setFieldsValue({ title });
    body && form.setFieldsValue({ body });
    description && form.setFieldsValue({ description });
    cover && form.setFieldsValue({ cover });
  }, [id, title, body, description, cover])

  const handleSubmit = (values) => {
    const { docId, title, body, description, cover } = values;
    const textValues = { title, body, description };
    firebase.updatePost({ docId, values: textValues })
    // history.push('/admin');
  }

  const handleDelete = async (id) => {
    await firebase?.deletePost(id)
    await history.push('/')
  };

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleSubmit}>
      <Form.Item hidden name="docId" />
      <Form.Item name="title">
        <Input />
      </Form.Item>
      <Form.Item name="description">
        <Input />
      </Form.Item>
      <Form.Item name="body">
        <ReactQuill theme="snow" value={body} onChange={setBody}/>
      </Form.Item>
      <ButtonContainer>
        <Button type="primary" htmlType="submit">
          Update
        </Button>
        <Popconfirm
            title="Are you sure to delete this post? This can't be undone."
            onConfirm={() => handleDelete(post?.docId)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">
              Delete
            </Button>
          </Popconfirm>
      </ButtonContainer>
    </Form>
  )
}

const ButtonContainer = styled(Form.Item)`
  max-width: 500px;
  margin: auto;
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
  }
  .ant-btn-primary {
    margin: 20px 0;
  }
`;