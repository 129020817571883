import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row } from 'antd'

export const ReorderScripts = ({ firebase, scripts }) => {
  const scriptsOrdered = scripts?.map(script => { 
    const { docId, order, title, cover } = script;
    return { docId, order, title, cover }
  }).sort((a, b) => a.order - b.order);

  const [scriptItems, setScriptItems] = useState(scriptsOrdered)

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 8;
  
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const onDragEnd = (result) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  const items = reorder(
    scriptItems,
    result.source.index,
    result.destination.index
  );
  console.log('items', items)
  setScriptItems(items);
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

useEffect(() => {
  if (scriptItems !== scriptsOrdered) {
    scriptItems?.map((item, index) => {
      console.log('999999~~', item.order)
      firebase?.updateScript({ docId: item.docId, values: { order: index } })
    })
  }
}, [scriptItems])
  
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {scriptItems?.map((item, index) => (
                <Draggable key={item.docId} draggableId={item.docId} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Row justify="center" style={{ maxWidth: "100px" }}>
                        <img src={item.cover} alt="" width="100%" />
                        <span style={{ textAlign: "center" }}>
                          {item.title}
                        </span>
                      </Row>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}}
      </Droppable>
    </DragDropContext>
  )
}
