import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tabs, Row } from 'antd'
import { EditBlog } from './EditBlog'
import { FileTextOutlined } from '@ant-design/icons'
import { storage } from "../../firebase/firebase"

export const EditBlogPage = ({ posts, firebase}) => {
  const history = useHistory();
  // posts = posts?.sort((a, b) => a.date - b.date)
  const { TabPane } = Tabs;

  return (
    <>
      <Row align="center">
        <h3><FileTextOutlined />&nbsp;Edit Blog Posts</h3>
      </Row>
      {
        posts?.length ? 
        <Tabs defaultActiveKey="1">
          {posts?.map((post, i) => {
            return (
              <TabPane tab={post.title} key={post.docId}>
                <EditBlog post={post} firebase={firebase} />
              </TabPane>
            )
          })}
        </Tabs> :
        <h4>
          No blog posts...
        </h4>
      }
    </>
  )
}